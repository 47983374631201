@font-face {
  font-family: OpenSans;
  src: url("../public/fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: OpenSans;
  src: url("../public/fonts/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: OpenSans;
  src: url("../public/fonts/OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
}

img {
  display: block;
}

body {
  font-family: OpenSans, sans-serif;
  min-height: 100vh;
}

header {
  padding: 0 !important;
}

body .MuiTypography-root,
.MuiInputBase-root input,
.MuiInputBase-root .MuiSelect-select,
.MuiTable-root .MuiTableCell-root {
  font-family: OpenSans, sans-serif;
}

.profileContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  row-gap: 5px;
  height: calc(100vh - 40px);
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}
.profileCard {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  row-gap: 20px;
  padding: 50px;
  border-radius: 5px;
  width: "fit-content";
  margin: auto;
  height: fit-content;
  justify-content: space-around;
}
@media only screen and (max-width: 600px) {
  .profileContainer{
    padding: 0px;
  }
  .profileCard{
    margin: 0px!important;
    height: 100%;
    width: 100%;
    padding: 0px;
    justify-content: center;
  }
}

button {
  text-align: center;
  text-transform: capitalize !important;
}
.loadingContainer {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100%;
  min-height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.profileContent {
  display: flex;
  flex-direction: column;
}
.profilerow {
  display: flex;
  width: 100%;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
}

.profileRowLable{
  width: 80px;
  
}
.profileRowValue{
  width: 100%;
  min-width: 250px;
}
.profileCenterContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  row-gap: 20px;
}
.profileActions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
